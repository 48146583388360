export type triangelProps = {
  viewBox: string
  className?: string
  // padding?: string
}

export const Triangel = (props: triangelProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={props.viewBox}
        className={props.className}
        // padding={props.padding}
      >
        <path
          d="M302.1,177.5c-16.2-19.8-36.2-37.1-59.7-50.7c-23.5-13.6-48.4-22.2-73.7-26.4c-17.8-2.9-35.4,7.2-41.8,24.1
      c-9.1,23.9-14,49.9-14,77c0,27.1,5,53.1,14,77c6.4,16.9,23.9,27,41.8,24.1c25.3-4.1,50.2-12.8,73.7-26.4
      c23.5-13.6,43.5-30.8,59.7-50.6C313.6,211.8,313.6,191.5,302.1,177.5z"
        />
      </svg>
    </>
  )
}
