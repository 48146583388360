import { Link } from "gatsby-plugin-react-i18next"
import { HeroTitle } from "../components/Styling/Titles"
import { TypographyLink } from "../components/Styling/Style"

import { Triangel, triangelProps } from "../components/Graphics/Triangel"

const NotFound = () => {
  const triangelOne: triangelProps = {
    viewBox: "100 100 220 220",
    className: "fill-current stroke-current stroke-0  text-tomat-500 opacity-10",
  }

  const triangelTwo: triangelProps = {
    viewBox: "100 100 220 220",
    className: "fill-current stroke-current stroke-0  text-rosmarin-500 opacity-20",
  }

  const triangelThree: triangelProps = {
    viewBox: "100 100 220 220",
    className: "fill-current stroke-current stroke-0 text-himmel-500 opacity-30",
  }
  return (
    <>
      <div className="px-24 h-128 relative">
        <div className="max-w-full z-0">
          {/* Tomat */}
          <div className="absolute left-6 md:left-64 xl:left-80 2xl:left-96 top-1 w-48 h-48 md:w-72 md:h-72 2xl:w-96 2xl:h-96">
            <Triangel {...triangelOne} />
          </div>

          {/* Himmel */}
          <div className="absolute right-0 md:right-28 xl:right-64 bottom-11 w-48 h-48 md:w-72 md:h-72 2xl:w-96 2xl:h-96 transform -rotate-37">
            <Triangel {...triangelThree} />
          </div>

          {/* Rosmarin */}
          <div className="absolute left-4 md:left-28 xl:left-64 bottom-1 w-48 h-48 md:w-72 md:h-72  2xl:w-96 2xl:h-96 transform -rotate-105">
            <Triangel {...triangelTwo} />
          </div>
        </div>
        {/* Header */}

        <div className="relative mx-auto lg:mx-8 ">
          <div className="text-center mb-8 md:mb-2 md:px-32 z-10">
            <HeroTitle>Obs!</HeroTitle>
            <HeroTitle>Vi fant ikke siden du så etter</HeroTitle>
          </div>

          <div className="mx-auto flex justify-center mt-16 mb-48 z-10">
            <span>
              Du kan bruke{" "}
              <Link to="/sok" className={TypographyLink}>
                søketjenesten
              </Link>{" "}
              for å finne alt innhold på nettsidene våre.
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
